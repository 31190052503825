import React from "react";
import {Card, CardBody, Image} from "@nextui-org/react";
import {Modal, ModalContent, ModalBody, useDisclosure} from "@nextui-org/react";


import '../css/gallery.css'
const Gallery=() =>{
  const {isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
  const [size, setSize] = React.useState('md')
  const [selectedImage, setSelectedImage] = React.useState('md')


  const handleOpen = (size,imgsrc) => {
    setSize(size)
    setSelectedImage(imgsrc);
    onOpen();
  }

  const list = [
    {
      title: "Lemon 2",
      img: "/assets/img/gallery/01.jpeg",
      price: "$8.00",
    },
    {
      title: "Banana",
      img: "/assets/img/gallery/1-_1 - Photo.jpg",
      price: "$7.50",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/1-_5 - Foto.jpg",
      price: "$12.20",
    },{
      title: "Orange",
      img: "/assets/img/gallery/02.png",
      price: "$5.50",
    },
    {
      title: "Tangerine",
      img: "/assets/img/gallery/03.jpg",
      price: "$3.00",
    },
    {
      title: "Raspberry",
      img: "/assets/img/gallery/10-_Photo - 2.jpg",
      price: "$10.00",
    },
    {
      title: "Lemon",
      img: "/assets/img/gallery/10-_Photo - 4.jpg",
      price: "$5.30",
    },
    {
      title: "Avocado",
      img: "/assets/img/gallery/10-_Photo - 5 (1).jpg",
      price: "$15.70",
    },
    {
      title: "Lemon 2",
      img: "/assets/img/gallery/10-_Photo - 5.jpg",
      price: "$8.00",
    },
    {
      title: "Banana",
      img: "/assets/img/gallery/15-_Photo - 2 (2).jpg",
      price: "$7.50",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/a-_5 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/AA_3 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/AL_PARK_R.png",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/AL_PARK_R-E.png",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/ans.png",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/BE-_6 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_12 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_15 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_17 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_18 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_20 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_28 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_29 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_30 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_32 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CC-_40 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CND (1).jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CND (2).jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CND (3).jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/COFFEE IT-_1 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CPB-05.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/CPB-06.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-01.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-02.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-03.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-04.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-05.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-06.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-07.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/GYM-R-08.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/II-.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/III-_9 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/III-_10 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/Imagen1.png",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/Imagen2.png",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/INOVA_CONSTITUYENTES_MOARCI__1 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/IT-_1 - Photo.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/IT-_2 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/IT-_3 - Foto.jpg",
      price: "$12.20",
    },
    {
      title: "Watermelon",
      img: "/assets/img/gallery/IT-_4 - Foto.jpg",
      price: "$12.20",
    }
  ];

    return (
      <>
      <div class="w-full px-0">
        <h1 className="p-5 text-5xl uppercase">Galeria</h1>

        
        
        <div className="gap-0 p-0 grid md:grid-cols-8 sm:grid-cols-4 grid-cols-5 ">
      {list.map((item, index) => (
        <Card shadow="sm" radius="none" key={index} isPressable /*key={size}*/ 
                                  onPress={() => handleOpen("full",item.img)}>
          <CardBody className="overflow-visible p-0" >
            <Image
              shadow="sm"
              radius="none"
              width="100%"
              alt={item.title}
              className="w-full  h-[140px]"
              src={item.img}
            />
          </CardBody>
        </Card>
      ))}
    </div>


    </div>
    {selectedImage && (
    <Modal 
       size={size} 
       isOpen={isOpen} 
       onClose={onClose} 
       onOpenChange={onOpenChange}
       className="bg-transparent"
      >
    <ModalContent>

          {(onClose) => (
            <>
              <ModalBody className="flex justify-center items-center w-full bg-transparent">
              <Image
              shadow="sm"
              radius="none"
              className="w-full h-full"
              src={selectedImage}
              onClick={onClose}
            />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      )}
    </>
  );
}

export default Gallery;